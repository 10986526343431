// export const API = '/api';
export const CLEAN_API = 'https://admin.zheo.ua';
// export const CLEAN_API = 'https://admin-test.zheo.ua';

export const API = `${CLEAN_API}/api`;
export const PHONE_REGEX_UA = /^\+?3?8?(0\d{9})$/;
export const PHONE_REGEX_KZ = /^\+?77(\d{9})$/;
export const LATIN_CHARACTERS = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;

export const ORDER_STATUS = {
    CREATED: 'Created', // коли створили
    SET_EMPLOYEE: 'SetEmployee',
    SET_INSPECTION_TIME: 'SetInspectionTime',
    EMPLOYEE_CONFIRMED: 'EmployeeConfirmed', // Погоджено виконавцем (Прийнято) (Опрацьовується)
    COMPLETED: 'Completed', // Завершено повністю
    DELETED: 'Deleted',
    EMPLOYEE_PERFORM: 'EmployeePerform', // Роб надіслав в 1С
    WAIT_EMPLOYEE_CONFIRM: 'WaitEmployeeConfirm',
    EMPLOYEE_REFUSE: 'EmployeeRefuse',
    CUSTOMER_REFUSE: 'CustomerRefuse',
    IN_PROCESS: 'InProcess', // Направлено виконавцю
    IN_WORK: 'InWork', // В роботі
    EXPLAINED: 'Explained' // Розʼяснено
}

export const ORDER_TYPES = {
    EMERGENCY: 'EmergencyServicesOrder',
    PAID: 'PaymentServicesOrder',
}
